<template>
  <div class="p-1">
    <b-row align-h="center" class="mt-2">
      <b-col xl="7" lg="8" md="12">
        <b-img
          src="https://cdn.cloudious.net/file-1698940788714-679165302.jpg"
          fluid
          class="mb-3"
        />

        <b-card
          v-for="(data, ind) in items"
          :key="ind"
          body-class="pl-md-0 pt-md-0 pb-md-0"
        >
          <b-row>
            <b-col lg="4" md="5" class="pr-0">
              <b-img
                :src="data.cover"
                alt="image"
                height="250px"
                class="w-100"
                style="object-fit: cover"
              />
              <!-- class="adjust-image h-100 w-100" -->
            </b-col>
            <b-col lg="8" md="7" class="pt-1">
              <h2>{{ data.name }}</h2>
              <h5 class="font-weight-normal">{{ data.about }}</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-1 mt-1"
                tag="a"
                :href="data.link"
                target="_blank"
              >
                <span>Donate Now</span>
              </b-button>
            </b-col>
          </b-row>

          <!-- <b-media vertical-align="center">
                <template #aside>
                  <b-img :src="data.cover" width="250" />
                </template>
                <div class="pt-1">
                  <h2>{{ data.name }}</h2>
                  <h5 class="font-weight-normal">{{ data.about }}</h5>
                </div>
              </b-media> -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BMediaBody,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    flatPickr,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },

  data() {
    return {
      items: [
        {
          cover: "https://www.map.org.uk/images/rs3802c15i9645_cropped.jpg",
          name: "Medical Aid Palestine",
          about:
            "Medical Aid for Palestinians (MAP) works for the health and dignity of Palestinians living under occupation and as refugees. We provide immediate medical aid to those in great need, while also developing local capacity and skills to ensure the long-term development of the Palestinian healthcare system. ",
          link: "https://www.map.org.uk/",
        },
        {
          cover:
            "https://launchgood.s3.amazonaws.com/project/176748/saving_lives_in_palestine_IMG_4223-698x525.jpeg",
          name: "Launch Good",
          about:
            "LaunchGood connects people from one corner of the world to another, embodying the global heartbeat of the Ummah. We believe Muslims have incredible values to share with the world. Together, we set out to build an inspired future.",
          link: "https://www.launchgood.com/campaign/saving_lives_in_palestine?src=internal_comm_page#!/",
        },
        {
          cover: "https://cdn.cloudious.net/file-1698939375974-330466132.png",
          name: "Islamic Relief",
          about:
            "Islamic Relief is a faith-inspired humanitarian and development agency working to save and transform the lives of some of the world’s most vulnerable people. Established in 1984, we now work in over 45 countries assisting individuals, families and communities through emergency response and development projects that typically help over 10 million people each year.",
          link: "https://islamic-relief.org/appeals/palestine-emergency-appeal/",
        },
        {
          cover: "https://humanappeal.org.uk/media/_cache/4c4e65c52899.jpg",
          name: "Human Appeal",
          about:
            "Human Appeal is a non-profit organisation working across the globe to strengthen humanity’s fight against poverty, social injustice and natural disaster. Through the provision of immediate relief and the establishment of self-sustaining development programmes, we aim to invest in real, effective solutions.",
          link: "https://humanappeal.org.uk/appeals/gaza-emergency-appeal",
        },
        {
          cover:
            "https://cdn-ihobd.nitrocdn.com/XKoeTKZKeyymHTMzPhliwbkNihpQjrNJ/assets/images/optimized/rev-d486faa/www.muslimglobalrelief.org/wp-content/uploads/2021/04/Gaza-Food-Packs-1.jpg",
          name: "Muslim Global Relief",
          about:
            "Muslim Global Relief has been “Bringing Hope To Life” for over 20 years alleviating world poverty, hunger & providing humanitarian relief in over 40+ counties worldwide.  We were Awarded the UK’s Best Muslim Charity of  the Year in 2018. and also was a Finalist in 2019 at the National British Muslim Awards.",
          link: "https://www.muslimglobalrelief.org/gaza-emergency-appeal/",
        },
        {
          cover:
            "https://www.savethechildren.org/content/dam/global/images/countries/kenya/kenya-disabilities-m218149-hero.jpg/_jcr_content/renditions/cq5dam.thumbnail.2562.2562.jpg",
          name: "Save the Children",
          about:
            "Save the Children has been providing essential services and support to Palestinian children impacted by the ongoing conflict since 1953, and have had a permanent presence in the occupied Palestinian territory since 1973. We are present in the West Bank and Gaza. The existing humanitarian situation for families in the occupied Palestinian territory is already dire.",
          link: "https://www.savethechildren.org/us/where-we-work/west-bank-gaza",
        },
        {
          cover:
            "https://donate.unrwa.org/cdn.iraiser.eu/PYo+yXbrJtjg1PvDuefZRg==/default/origin/B3A1274-0.jpg",
          name: "UNRWA",
          about:
            "More than 2 million people, including 1.7 million Palestine Refugees, are paying the devastating price of the escalation in the Gaza Strip. Civilians are dying while the world watches. Airstrikes continue. Families are being displaced en masse. Lifesaving supplies are running out. Humanitarian access is still denied.",
          link: "https://donate.unrwa.org/gaza/~my-donationa",
        },
      ],
    };
  },

  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
@media (max-width: 768px) {
  .adjust-image {
    max-height: 200px;
  }
}
</style>
